import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "landing-view flex" }
const _hoisted_2 = { class: "title-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Particles = _resolveComponent("Particles")!
  const _component_PageTitleComponent = _resolveComponent("PageTitleComponent")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Particles, {
      id: "particles",
      type: "cobweb",
      color: "#176bc0",
      options: {
                fpsLimit: 60,
                particles: {
                    color: {
                        value: '#176bc0',
                    },
                    links: {
                        color: '#176bc0',
                        distance: 150,
                        enable: true,
                        opacity: 0.4,
                        width: 1,
                    },
                    collisions: {
                        enable: true,
                    },
                    move: {
                        direction: 'none',
                        enable: true,
                        outMode: 'bounce',
                        random: false,
                        speed: 2,
                        straight: false,
                    },
                    number: {
                        density: {
                            enable: true,
                            value_area: 1000,
                        },
                        value: 60,
                    },
                    opacity: {
                        value: 0.4,
                    },
                    shape: {
                        type: 'circle',
                    },
                    size: {
                        random: true,
                        value: 5,
                    },
                },
                detectRetina: true,
            }
    }, null, 8, ["options"]),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_PageTitleComponent, {
        title: "William Baker",
        subheading: "Software Developer"
      })
    ])
  ]))
}