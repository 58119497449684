import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "timeline-component flex flex-vertical" }
const _hoisted_2 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.displayItems, (item, index) => {
      return (_openBlock(), _createElementBlock("div", {
        key: `timeline-item-${index}`,
        class: "item"
      }, [
        _createElementVNode("h6", null, [
          _createElementVNode("div", {
            class: _normalizeClass(["node", { 'has-icon': item.type !== _ctx.TimelineType.None }])
          }, [
            (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.mapIcon(item.type))))
          ], 2),
          _createElementVNode("span", null, _toDisplayString(_ctx.mapDate(item.date)), 1),
          (item.endDate !== null)
            ? (_openBlock(), _createElementBlock("span", _hoisted_2, " — " + _toDisplayString(_ctx.mapEndDate(item.endDate)), 1))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("p", null, _toDisplayString(item.summary), 1)
      ]))
    }), 128))
  ]))
}