
import { defineComponent } from 'vue';

import PageTitleComponent from '@/component/layout/PageTitle.component.vue';

export default defineComponent({
    name: 'LandingView',

    components: {
        PageTitleComponent,
    },

    setup() {},
});
