
import { Component, computed, defineComponent, PropType } from 'vue';
import dayjs, { Dayjs } from 'dayjs';

import EducationIcon from '@/component/icon/GraduationCapIcon.component.vue';
import WorkIcon from '@/component/icon/BriefcaseIcon.component.vue';

import { Timeline, TimelineItem, TimelineType } from '@/model/Timeline.model';

export default defineComponent({
    name: 'TimelineComponent',

    props: {
        timeline: {
            type: Object as PropType<Timeline>,
            required: true,
        },
    },

    setup(props) {
        const today: TimelineItem = {
            date: dayjs(),
            endDate: null,
            type: TimelineType.None,
            summary: 'Thank you for visiting my website :)',
        };

        const displayItems = computed<Array<TimelineItem>>(() => [ today ].concat(props.timeline.items));

        return {
            TimelineType,
            displayItems,

            mapIcon(type: TimelineType): Component | null {
                switch (type) {
                    case TimelineType.None: return null;
                    case TimelineType.Education: return EducationIcon;
                    case TimelineType.Work: return WorkIcon;
                    default:
                        return null;
                }
            },

            mapDate(date: Dayjs): string {
                if (date.isToday())
                    return 'Today!';

                return date.format('MMMM YYYY');
            },

            mapEndDate(endDate: Dayjs): string {
                if (endDate.isToday())
                    return 'Present';

                return endDate.format('MMMM YYYY');
            },
        }
    },
});
