
import { defineComponent } from 'vue';

import { IconProps } from '@/component/type/IconProps';

export default defineComponent({
    name: 'BriefcaseIcon',

    props: IconProps,

    setup() {},
});
