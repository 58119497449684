
import { defineComponent } from 'vue';

import LinkComponent from '@/component/Link.component.vue';
import PageContentComponent from '@/component/layout/PageContent.component.vue';

export default defineComponent({
    name: 'AboutCvView',

    components: {
        PageContentComponent,
        LinkComponent,
    },

    setup() {},
});
